import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

class Nav extends React.Component {
  render() {
    // const { location, title, children } = this.props
    const { selected, selectedcolor } = this.props
    console.log(selected)
    return (
      <NavContainer id="Nav">
        <NavWrapper>
          <Logo to="/" selectedcolor={selectedcolor}>
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 915.32 919.29"
            >
              <path
                className="cls-1"
                d="M294 430.35l-141.06-1.96L0 581.33l335.81 7.85v330.11l153.67-153.67V626.17l68.3 68.3 109.56-109.56-333.67-337.23-111.35 112.06L294 430.35z"
              />
              <path
                className="cls-1"
                d="M1587 919.76h-1c-140.87-145.13-264.8-80.31-334.56-10.55l-156.78 156.37L1431 1403.34l159.56-161c69.75-69.73 119.04-199.96-3.56-322.58zm-183.78 276.68l-98.6-99 69.28-70.44c49-45.9 93.88 9 93.88 9 48.87 48.58 6.31 90.75 6.31 90.75z"
                transform="translate(-746.57 -832.66)"
              />
            </svg>
          </Logo>
          <Links>
            <LinksContainer>
              <NavLink to="writing">Writing</NavLink>
              <NavLink
                selected={selected}
                selectedcolor={selectedcolor}
                to="about"
              >
                About
              </NavLink>
              <NavLink
                selected={selected}
                selectedcolor={selectedcolor}
                to="sprint"
              >
                Sprint
              </NavLink>
            </LinksContainer>
          </Links>
        </NavWrapper>
      </NavContainer>
    )
  }
}

const NavLink = styled(Link)`
  && {
    ${props =>
      props.selected === props.to
        ? `
        color: ${
          props.selected ? props.selectedcolor || 'red' : null
        } !important;
        border-bottom: 2px solid ${
          props.selected ? props.selectedcolor || 'red' : null
        } !important;
        display: inline-block;
        line-height: 24px;
        `
        : 'color: rgba(255,255,255,0.4) !important;'}
  }
`

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  flex-direction: row;
  z-index: 999;
`

const NavWrapper = styled.div`
  max-width: 900px;
  width: 100%;
  width: 100%;
  flex-direction: row;
  display: flex;
  @media (max-width: 1000px) {
    margin: 0 5vw;
  }
`

const Links = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
`

const LinksContainer = styled.div`
  text-align: right;
  justify-content: center;
  height: 100%;
  align-items: center;
  a {
    line-height: 100px;
    border-bottom: none !important;
    font-family: Inter, sans-serif;
    // opacity: 0.5;
    color: rgb(141, 141, 141) !important;
    font-weight: 600;
    margin-left: 24px;
  }
`

const Logo = styled(Link)`
  background-color: ${props =>
    props.selectedcolor ? props.selectedcolor : 'red'};
  display: inline-block;
  color: white;
  padding: 224px 16px 16px;
  font-weight: 200;
  font-family: Inter;
  align-items: center;
  border-bottom: none !important;
  margin-top: -200px;
  transition: transform 200ms ease-in-out;
  :hover {
    transform: translate3d(0, 8px, 0);
  }
  ::before {
    content: 'Hello.';
    font-family: Inter, sans-serif;
    position: absolute;
    top: -50px;
    color: white;
    font-size: 8px;
    font-weight: 900;
  }
  svg {
    width: 50px;
    path {
      fill: white;
    }
  }
`

export default Nav
