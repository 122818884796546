import React from 'react'
import { Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'

class Footer extends React.Component {
  render() {
    return (
      <FooterContainer id="Nav">
        <Wrapper>
          <Logo to="/">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 915.32 919.29"
            >
              <path
                className="cls-1"
                d="M294 430.35l-141.06-1.96L0 581.33l335.81 7.85v330.11l153.67-153.67V626.17l68.3 68.3 109.56-109.56-333.67-337.23-111.35 112.06L294 430.35z"
              />
              <path
                className="cls-1"
                d="M1587 919.76h-1c-140.87-145.13-264.8-80.31-334.56-10.55l-156.78 156.37L1431 1403.34l159.56-161c69.75-69.73 119.04-199.96-3.56-322.58zm-183.78 276.68l-98.6-99 69.28-70.44c49-45.9 93.88 9 93.88 9 48.87 48.58 6.31 90.75 6.31 90.75z"
                transform="translate(-746.57 -832.66)"
              />
            </svg>
          </Logo>
        </Wrapper>
      </FooterContainer>
    )
  }
}

// <Top
// onClick={() => {
//   window.scroll({
//     top: 0,
//     left: 0,
//     behavior: 'smooth',
//   })
// }}
// >
// ↑
// </Top>

// <Top>↑</Top>

// const topAnimation = keyframes`
//   0% {
//     line-height: 40px;
//   }
//   50% {
//     line-height: 60px;
//   }
//   100% {
//     line-height: 40px;
//   }
// `

// const Top = styled.div`
//   float: right;
//   font-family: Inter, sans-serif;
//   font-size: 40px;
//   width: 40px;
//   height: 40px;
//   line-height: 40px;
//   color: white;
//   border-top: 4px solid white;
//   cursor: pointer;
//   :hover {
//     animation: ${topAnimation} 400ms ease-in-out 2;
//   }
// `

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  padding: 100px 0;
  @media (max-width: 680px) {
    padding: 50px 0;
  }
`
const Logo = styled.div`
  display: inline-block;
  svg {
    width: 50px;
    path {
      fill: white;
    }
  }
`

const Wrapper = styled.div`
  max-width: 900px;
  margin: 0 24px;
  width: 100%;
  @media (max-width: 1000px) {
    width: inherit;
    margin: 0 5vw;
  }
`

export default Footer
