import React from 'react'
import { Link } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'
import moment from 'moment'

import SEO from '../components/seo'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Superscript from '../components/Superscript'

import foxquiltScreen1 from '../assets/foxquilt-screen1.jpg'
import liftScreen1 from '../assets/lift-screen1.jpg'
import kikisScreen1 from '../assets/kikis-screen1.jpg'
import sessionwireScreen1 from '../assets/sessionwire-screen1.jpg'
import edhScreen1 from '../assets/edh-screen1.jpg'
import tomatoScreen1 from '../assets/tomato-screen1.jpg'

class Index extends React.Component {
  render() {
    return (
      <Container>
        <SEO />
        <Nav selectedColor="red" />
        <Wrapper>
          <Superscript>Design for humans.</Superscript>
          <Headline>
            <p>
              Karl Danninger is a software developer, designer, and creative
              director who guides teams to success through good design.
            </p>
            <p>
              Currently working on{' '}
              <a href="https://www.atlistmaps.com">Atlist Maps</a>, an easy way
              to create and share beautiful maps. Previously the principal
              designer and senior software engineer at{' '}
              <a href="https://www.okgrow.com">OK GROW!</a> for 7+ years,
              formerly co-founder of{' '}
              <a href="http://hustlehacker.com">Hustle Hacker</a>.
            </p>
          </Headline>
          <More>
            <p>
              {/*
              You can read more <Link to="about">about</Link> Karl. He
              occasionally{' '}
              <a href="https://twitter.com/KarlDanninger">tweets</a> his mind,
              and takes pretty{' '}
              <a href="https://www.instagram.com/karldanninger/">photos</a>.
            */}
              → <Link to="about">About</Link>
              <br />→ <a href="https://twitter.com/KarlDanninger">Twitter</a>
              <br />→{' '}
              <a href="https://www.instagram.com/karldanninger/">Instagram</a>
              <br />→{' '}
              <a href="https://www.linkedin.com/in/karldanninger/">LinkedIn</a>
            </p>
            <p>
              Inquiries?{' '}
              <a href="mailto:work@danninger.co">work@danninger.co</a>
            </p>
          </More>
        </Wrapper>

        <Portfolio backgroundColor="#46b2bb" id="foxquilt">
          <Wrapper>
            <Number>↓ KRLD - 0001</Number>
            <h3>
              Foxquilt
              <Description>
                {' '}
                – Empowering communities to come together and save, with a smart
                and innovative approach to insurance.
              </Description>
            </h3>
            <img src={foxquiltScreen1} alt="foxquilt screen 1" />
            <Small>
              <tbody>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Project:{' '}
                  </td>
                  <td>
                    Quoting Application via{' '}
                    <a href="https://www.okgrow.com">OK GROW!</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Role:
                  </td>
                  <td>Creative Director & Developer</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Tech:
                  </td>
                  <td>React, GraphQL, Apollo </td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Date:
                  </td>
                  <td>2018</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Link:
                  </td>
                  <td>
                    <a href="https://join.foxquilt.com/application">
                      join.foxquilt.com
                    </a>
                  </td>
                </tr>
              </tbody>
            </Small>
          </Wrapper>
        </Portfolio>

        <Portfolio backgroundColor="rgba(230,113,102,1)" id="liftco">
          <Wrapper>
            <Number>↓ KRLD - 0002</Number>
            <h3>
              Lift & Co
              <Description>
                {' '}
                – Empowering the cannabis industry to make better-informed
                decisions through superior information and data.
              </Description>
            </h3>
            <img src={liftScreen1} alt="lift screen 1" />
            <Small>
              <tbody>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Project:
                  </td>
                  <td>
                    Recommender & Expo Applications via{' '}
                    <a href="https://www.okgrow.com">OK GROW!</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Role:
                  </td>
                  <td>Developer</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Tech:
                  </td>
                  <td>React Native, GraphQL, Apollo</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Date:
                  </td>
                  <td>2018</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Link:
                  </td>
                  <td>
                    <a href="https://lift.co/">lift.co</a>
                  </td>
                </tr>
              </tbody>
            </Small>
          </Wrapper>
        </Portfolio>

        <Portfolio backgroundColor="#faf6f6" fontColor="#ff85a2" id="kikis">
          <Wrapper>
            <Number fontColor="#ff85a2">↓ KRLD - 0003</Number>
            <h3>
              Kiki's Delivery
              <Description>
                {' '}
                – Enabling couriers and messengers to deliver more efficiently
                by optimizing routes tailored for bicycles.
              </Description>
            </h3>
            <img src={kikisScreen1} alt="kikis screen 1" />
            <Small fontColor="#ff85a2">
              <tbody>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Project:
                  </td>
                  <td>Delivery Route Optimizer</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Role:
                  </td>
                  <td>Owner</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Tech:
                  </td>
                  <td>Meteor, React, MongoDB</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Date:
                  </td>
                  <td>2016 - Present</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Link:
                  </td>
                  <td>
                    <a href="https://kikis.delivery/">kikis.delivery</a>
                  </td>
                </tr>
              </tbody>
            </Small>
          </Wrapper>
        </Portfolio>

        <Portfolio backgroundColor="rgb(48, 64, 73)" id="sessionwire">
          <Wrapper>
            <Number>↓ KRLD - 0004</Number>
            <h3>
              Sessionwire
              <Description>
                {' '}
                – Facilitating a community of musicians and producers to make it
                possible to collaborate from around the world.
              </Description>
            </h3>
            <img src={sessionwireScreen1} alt="Sessionwire screen 1" />
            <Small>
              <tbody>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Project:
                  </td>
                  <td>Sessionwire Communications Inc.</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Role:
                  </td>
                  <td>Creative Director & Developer</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Tech:
                  </td>
                  <td>Meteor, React, MongoDB, Stripe</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Date:
                  </td>
                  <td>2017</td>
                </tr>
              </tbody>
            </Small>
          </Wrapper>
        </Portfolio>

        <Portfolio
          gradientFrom="#048EC5"
          gradientTo="#2ECC71"
          gradientDirection="to top right"
          id="eatdrinkhealthy"
        >
          <Wrapper>
            <Number>↓ KRLD - 0005</Number>
            <h3>
              Eat Drink Healthy
              <Description>
                {' '}
                – Enabling people to easily find and share healthy food options
                nearby.
              </Description>
            </h3>
            <img src={edhScreen1} alt="Eat Drink Healthy screen 1" />
            <Small>
              <tbody>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Project:
                  </td>
                  <td>EatDrinkHealthy LLC.</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Role:
                  </td>
                  <td>Creative Director & Developer</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Tech:
                  </td>
                  <td>Meteor, React, MongoDB, Cordova</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Date:
                  </td>
                  <td>2016</td>
                </tr>
              </tbody>
            </Small>
          </Wrapper>
        </Portfolio>

        <Portfolio backgroundColor="#e84d3c" id="tomato">
          <Wrapper>
            <Number>↓ KRLD - 0006</Number>
            <h3>
              Tomato
              <Description>
                {' '}
                – Empowering teams to utilize the Pomodoro Technique and share
                together.
              </Description>
            </h3>
            <img src={tomatoScreen1} alt="Tomato screen 1" />
            <Small>
              <tbody>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Project:
                  </td>
                  <td>
                    Tomato via <a href="https://www.okgrow.com">OK GROW!</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Role:
                  </td>
                  <td>Lead Designer & Developer</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Tech:
                  </td>
                  <td>Meteor, MongoDB, Cordova</td>
                </tr>
                <tr>
                  <td>
                    <Arrow>→</Arrow> Date:
                  </td>
                  <td>2014</td>
                </tr>
              </tbody>
            </Small>
          </Wrapper>
        </Portfolio>
        <Footer />
      </Container>
    )
  }
}

export default Index

const Arrow = styled.span`
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-weight: 600;
  vertical-align: top;
`

const Number = styled.span`
  color: white;
  font-size: 10px;
  letter-spacing: 1px;
  color: ${props => (props.fontColor ? props.fontColor : 'white')};
  font-family: Inter, sans-serif;
  font-weight: 600;
  opacity: 0.6;
`

const Small = styled.table`
  color: ${props => (props.fontColor ? props.fontColor : 'white')};
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  opacity: 0.6;
  font-family: Ubuntu Mono;
  width: auto;
  border: none;
  td {
    padding: 0;
    border: none;
    padding-right: 12px;
  }
  tr {
    td:first-child {
      white-space: nowrap;
      vertical-align: top;
    }
  }
  && a {
    text-decoration: none;
    border-bottom: 2px solid
      ${props =>
        props.fontColor ? props.fontColor : 'rgba(255, 255, 255, 0.25)'};
    padding-bottom: 2px;
    cursor: pointer;
    color: ${props => (props.fontColor ? props.fontColor : 'white')};
  }
`

const Portfolio = styled.div`
  display: flex;
  background-color: ${props => props.backgroundColor};
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  ${props =>
    props.gradientFrom
      ? `
  background-image: linear-gradient(${props.gradientDirection}, ${props.gradientFrom}, ${props.gradientTo});
  `
      : null}
  @media (max-width: 680px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  h3 {
    font-size: 42px;
    letter-spacing: -1px;
    color: ${props => (props.fontColor ? props.fontColor : 'white')};
    font-family: Inter, sans-serif;
    line-height: 1.25;
    @media (max-width: 680px) {
      font-size: 24px;
      letter-spacing: -0.5px;
    }
  }
`

const Description = styled.span`
  font-weight: 300;
  opacity: 0.66;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
  @media (max-width: 680px) {
    padding-top: 150px;
  }
  a {
    color: white;
    text-decoration: none;
    box-shadow: none;
    white-space: nowrap;
    border-bottom: 3px solid rgba(255, 255, 255, 1);
    @media (max-width: 680px) {
      border-bottom: 2px solid rgba(255, 255, 255, 1);
    }
  }
`

const Wrapper = styled.div`
  max-width: 900px;
  margin: 0 24px;
  width: 100%;
  @media (max-width: 1000px) {
    width: inherit;
    margin: 0 5vw;
  }
`

const Headline = styled.h1`
  color: white;
  font-size: 30px;
  line-height: 1.6;
  font-weight: 400;
  font-family: Inter, sans-serif;
  margin: 100px 0 0;
  margin-left: 25%;
  p::-moz-selection {
    background-color: red;
  }
  p::selection {
    background-color: red;
  }
  a::-moz-selection {
    background-color: red;
  }
  a::selection {
    background-color: red;
  }
  @media (max-width: 680px) {
    font-size: 18px;
    margin-top: 50px;
  }
`

const More = styled.h1`
  color: white;
  font-size: 30px;
  line-height: 1.6;
  font-weight: 400;
  font-family: Inter, sans-serif;
  margin: 0 0 100px;
  margin-left: 25%;
  p::-moz-selection {
    background-color: red;
  }
  p::selection {
    background-color: red;
  }
  a::-moz-selection {
    background-color: red;
  }
  a::selection {
    background-color: red;
  }
  @media (max-width: 680px) {
    font-size: 18px;
    margin-bottom: 50px;
  }
`

const Links = styled.p`
  font-family: Inter, sans-serif;
  color: white;
  margin: 40px 0;
`

const LinkTag = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: none;
  box-shadow: none;
  white-space: nowrap;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
`
